<script lang="ts" setup>
import useDataRequestorState from '~/src/UserInterface/DataRequest/composables/useDataRequestorState'

const { getDataRequest } = useDataRequestorState()
const dataRequest = getDataRequest()
</script>

<template>
  <div class="main-content d-flex flex-column">
    <data-request-navbar-top :image="dataRequest.organisation.logo" />

    <div class="flex-grow-1">
      <slot />
    </div>

    <div
      id="footer"
      class="bg-white w-100"
    >
      <layout-footer />
    </div>
    <portal-target id="app-bottom" />
  </div>
</template>
